/** @format */
document.addEventListener('DOMContentLoaded', function () {
  document.body.classList.remove('no-js');
});

import "preline/preline";

import Alpine from "alpinejs";

window.Alpine = Alpine;

Alpine.start();

// Load the external SVG sprite sheet
fetch("/assets/logos.svg")
	.then((response) => response.text())
	.then((data) => {
		const spriteContainer = document.getElementById("svg-sprite");
		spriteContainer.innerHTML = data;

		// Function to set the viewBox attribute dynamically
		document.querySelectorAll("svg[data-symbol-id]").forEach((svg) => {
			const symbolId = svg.getAttribute("data-symbol-id");
			const symbol = document.getElementById(symbolId);
			if (symbol) {
				const viewBox = symbol.getAttribute("viewBox");
				if (viewBox) {
					svg.setAttribute("viewBox", viewBox);
				}
			}
		});
	})
	.catch((error) => console.error("Error loading the SVG sprite:", error));

// Homepage fader
const images = document.querySelectorAll('.fade');
let currentIndex = 0;
const totalImages = images.length;

// Only run slideshow if images exist
if (totalImages > 0) {
  // Function to show the next image
  function showNextImage() {
    images[currentIndex].classList.remove('active');
    currentIndex = (currentIndex + 1) % totalImages;
    images[currentIndex].classList.add('active');
  }

  // Initially show the first image and start the fade sequence after a delay
  images[0].classList.add('active');

  // Start the interval after a small delay
  setTimeout(() => {
    setInterval(showNextImage, 4000); // Start fading after 4 seconds
  }, 1000);
}

